import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import NotFound from "../views/404/404";
import LoadingPage from "../components/loading/loading-page";
import Home from "../views/home/home";
import Login from "../views/login/login";
let HomeData = React.lazy(() => import("../views/home-data/home-data"));
let BlogManage = React.lazy(() => import("../views/blog-manage/blog-manage"));
let UserManage = React.lazy(() => import("../views/user-manage/user-manage"));
let SiteRecommend = React.lazy(() =>
  import("../views/site-recommend/site-recommend")
);
let SystemSetting = React.lazy(() =>
  import("../views/system-setting/system-setting")
);
let Edit = React.lazy(() => import("../views/blog-manage/edit/edit"));

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" replace />, // 根路径重定向到 /home
  },
  {
    path: "home",
    element: <Home />,
    title: "home",
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<LoadingPage />}>
            <HomeData />
          </Suspense>
        ),
      },
      {
        path: "blogs",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <BlogManage />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <UserManage />
          </Suspense>
        ),
      },
      {
        path: "site",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SiteRecommend />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <SystemSetting />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
    title: "login",
  },
  {
    path: "edit",
    element: (
      <Suspense fallback={<LoadingPage />}>
        <Edit />
      </Suspense>
    ),
    title: "edit",
  },
  {
    path: "*",
    element: <NotFound />,
    title: "404",
  },
]);

export default routes;
